import { extractLanguage } from './lang/lang';
import { configureFetchDisplayMessages } from './lang/services';
import { configureMockUserManager, configureUserManager, createUserManager, SessionRenewedResult } from './login/login';
import { accessToken } from './tokenHandling/accessToken';
import { trace } from './setup/trace';
import { attemptInitialSignIn } from './setup/oauth';
import { config } from '../config';
import { store } from './setup/store';
import { accessTokenStored, idTokenClaimsStored, idTokenStored } from './tokenHandling/tokenSlice';
import { userProfileObtained, userSessionExpired, userSessionRenewed } from './login/loginSlice';
import { getLocale } from './lang/langSlice';
import { User, UserManager } from 'oidc-client-ts';

export interface OAuthConfig {
    onSessionExpired: () => void;
    onSessionRenewed: (arg: SessionRenewedResult) => void;
}

export const main = async (renderApp: (arg: { user: User; logout: () => void }) => void) => {
    const fetchDisplayMessages = configureFetchDisplayMessages(store);

    // We want the `<html lang>` attribute to be synced with the
    // language currently displayed
    store.subscribe(() => {
        const lang = extractLanguage(getLocale(store.getState()));
        const html = document.querySelector('html');

        if (html && lang && html.getAttribute('lang') !== lang) {
            html.setAttribute('lang', lang);
        }
    });

    const oauthConfig: OAuthConfig = {
        onSessionExpired: () => {
            accessToken.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onSessionRenewed: (result: SessionRenewedResult) => {
            trace('index.onTokenRenewed', result);

            accessToken.saveAccessToken(result.accessToken);
            store.dispatch(accessTokenStored(result.accessToken));
            store.dispatch(idTokenStored(result.idToken));
            store.dispatch(idTokenClaimsStored(result.idTokenClaims));
            store.dispatch(userProfileObtained(result.profile));

            store.dispatch(userSessionRenewed());

            // You will need to get the user language yourself then
            // you may fetch the suitable messages. Depending
            // on when and from where you fetch the user settings you might
            // want to employ a loading spinner while the request is ongoing.
            fetchDisplayMessages(result.locale);
        },
    };

    const isAllowedToMockAuth = import.meta.env.DEV;
    const userManager: UserManager =
        isAllowedToMockAuth && config.login.mockAuthorization
            ? configureMockUserManager(oauthConfig)
            : configureUserManager(oauthConfig, createUserManager());

    // document.addEventListener('EVENT_USER_LANGUAGE_CHANGED', userManager.signinSilent.bind(userManager));
    // document.addEventListener('EVENT_USER_PROFILE_CHANGED', userManager.signinSilent.bind(userManager));

    try {
        await userManager.clearStaleState();
        const user = await attemptInitialSignIn(userManager);
        renderApp({ user: user!, logout: () => {} });
    } catch (error) {
        trace('could not start application', error);
        console.error(error);
    }
};
